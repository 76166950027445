// Generated by Framer (d894e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle1 from "../css/pIKVBItbQ";
import * as sharedStyle from "../css/Trr52nKSH";

const enabledGestures = {qPmIKVOf6: {hover: true}};

const cycleOrder = ["qPmIKVOf6"];

const serializationHash = "framer-CXgoT"

const variantClassNames = {qPmIKVOf6: "framer-v-pntdc6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, eg6VJpWca: title ?? props.eg6VJpWca ?? "Angebot"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, eg6VJpWca, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "qPmIKVOf6", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-CXgoT", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-pntdc6", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qPmIKVOf6"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"qPmIKVOf6-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 className={"framer-styles-preset-6tspdq"} data-styles-preset={"Trr52nKSH"} style={{"--framer-text-alignment": "left"}}>Angebot</motion.h1></React.Fragment>} className={"framer-vokv0e"} layoutDependency={layoutDependency} layoutId={"zuD3AMQew"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={eg6VJpWca} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"qPmIKVOf6-hover": {children: <React.Fragment><motion.h1 className={"framer-styles-preset-l1llul"} data-styles-preset={"pIKVBItbQ"}>Angebot</motion.h1></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-CXgoT [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CXgoT .framer-sa95i3 { display: block; }", ".framer-CXgoT .framer-pntdc6 { height: 96px; overflow: visible; position: relative; width: 332px; }", ".framer-CXgoT .framer-vokv0e { bottom: 0px; flex: none; height: auto; position: absolute; right: 0px; white-space: pre; width: auto; }", ".framer-CXgoT .framer-v-pntdc6 .framer-pntdc6 { cursor: pointer; }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 96
 * @framerIntrinsicWidth 332
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"sBJSBM7Ew":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"eg6VJpWca":"title"}
 * @framerImmutableVariables false
 */
const FramerrvZWmz3bc: React.ComponentType<Props> = withCSS(Component, css, "framer-CXgoT") as typeof Component;
export default FramerrvZWmz3bc;

FramerrvZWmz3bc.displayName = "menu-angebot";

FramerrvZWmz3bc.defaultProps = {height: 96, width: 332};

addPropertyControls(FramerrvZWmz3bc, {eg6VJpWca: {defaultValue: "Angebot", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerrvZWmz3bc, [...sharedStyle.fonts, ...sharedStyle1.fonts])